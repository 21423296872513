<template>
  <main class="main services-page">
    <div class="container">
      <BreadcrumbsComponent :title="page ? page.title : 'Услуги'" />
      <kinesis-container class="prg">
        <section class="prg__content">
          <div class="prg__tabs" v-if="vehicles.length">
            <h2 class="prg__tabs__title" v-if="vehicles.length">Типы имуществ</h2>
            <div class="prg__tabs__buttons" v-if="vehicles.length">
              <router-link
                :to="{
                  name: 'service',
                  params: {
                    id: vehicle.id,
                    url: vehicle.link,
                  },
                }"
                class="vehicle-btn"
                v-for="(vehicle, index) in vehicles"
                :key="index"
                type="button"
              >
                <i class="icon" v-html="vehicle.icon"></i>
                <span>{{ vehicle.button_title || vehicle.title }}</span>
              </router-link>
            </div>
          </div>
          <div class="prg__reasons">
            <h2 class="prg__reasons__title">Почему Лизинг?</h2>
            <ul class="prg__reasons__list">
              <li>
                <span class="prg__reasons__digit">1.</span>
                <span>Не влияет на кредитную нагрузку</span>
              </li>
              <li>
                <span class="prg__reasons__digit">2.</span>
                <span>Экономия на налогах</span>
              </li>
              <li>
                <span class="prg__reasons__digit">3.</span>
                <span>Принцип одного окна при страховании и регистрации имущества</span>
              </li>
            </ul>
            <div class="prg__act">
              <div class="prg__act__buttons">
                <!--                <router-link :to="{ name: 'request' }" class="btn">Отправить заявку</router-link>-->
                <router-link :to="{ name: 'faq' }" class="btn-dark">
                  <InfoCircleIcon />
                  <span>FAQ</span>
                </router-link>
              </div>
            </div>
          </div>
        </section>
        <section class="prg__footer" v-if="advantages.length">
          <h2 class="prg__tabs__title">Преимущества Лизинг-Трейд</h2>
          <div class="prg__footer__content">
            <div class="prg__footer__img">
              <kinesis-element type="translate" :strength="$mq === 'lg' ? 15 : 0">
                <img
                  loading="lazy"
                  decoding="async"
                  src="/public/images/pen.png"
                  alt="Преимущества Лизинг-Трейд"
                />
              </kinesis-element>
            </div>
            <ul class="prg__footer__list">
              <template v-for="(advantage, index) in advantages">
                <li :key="index" v-if="advantage !== null">
                  <h3 class="prg__footer__list__title">{{ advantage.title }}</h3>
                  <div class="prg__footer__list__desc">
                    <EditorJSComponent :text="getAdvantagesDescription(advantage)" />
                  </div>
                </li>
                <li class="img" :key="index" v-else>
                  <kinesis-element type="depth" :strength="$mq === 'lg' ? 15 : 0">
                    <img
                      loading="lazy"
                      decoding="async"
                      src="/public/images/speedometer.gif"
                      alt="Преимущества Лизинг-Трейд"
                    />
                  </kinesis-element>
                </li>
              </template>
            </ul>
            <div class="prg__footer__actions">
              <span>Информация, размещенная на данной странице, не является публичной офертой.</span>
              <router-link :to="{ name: 'request' }" class="btn">Отправить заявку</router-link>
            </div>
          </div>
        </section>
      </kinesis-container>
    </div>
  </main>
</template>

<script>
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import InfoCircleIcon from "components/svg/InfoCIrcle.vue";
import { KinesisContainer, KinesisElement } from "vue-kinesis";
import EditorJSComponent from "components/EditorJSComponent.vue";
import { cityIn } from "lvovich";

export default {
  name: "VehiclesPage",
  async asyncData({ store, link }) {
    store.state.alternateLink = link;
    await store.dispatch("GET_VEHICLES_PAGE");
  },
  computed: {
    page() {
      let page;
      if (this.$store.state.vehicles_page && this.$store.state.vehicles_page.page) {
        page = this.$store.state.vehicles_page.page;
      }
      return page;
    },
    advantages() {
      if (
        this.$store.state.vehicles_page &&
        this.$store.state.vehicles_page.advantages &&
        this.$store.state.vehicles_page.advantages.length
      ) {
        const advantages = JSON.parse(
          JSON.stringify(this.$store.state.vehicles_page.advantages.map((adv) => adv.advantage))
        );
        advantages.splice(2, 0, ...[null]);
        return advantages;
      } else {
        return [];
      }
    },
    vehicles() {
      if (
        this.$store.state.vehicles_page &&
        this.$store.state.vehicles_page.vehicles &&
        this.$store.state.vehicles_page.vehicles.length
      ) {
        const vehicles = this.$store.state.vehicles_page.vehicles;
        return vehicles.sort((a, b) => a.position - b.position);
      } else {
        return [];
      }
    },
  },
  methods: {
    getImg(item) {
      if (item.head_img && item.head_img.img && item.head_img.img.url) {
        return this.$store.state.api + item.head_img.img.url;
      }
    },
    getAdvantagesDescription(adv) {
      if (adv.description) {
        let haveError = false;
        try {
          JSON.parse(adv.description);
        } catch (e) {
          haveError = true;
        }
        if (!haveError) {
          return JSON.parse(adv.description);
        } else {
          return {
            blocks: [
              {
                type: "paragraph",
                data: { text: adv.description },
              },
            ],
          };
        }
      }
      return {
        blocks: [],
      };
    },
  },
  metaInfo() {
    let api = this.$store.state.api;
    let metaName = this.page.title;
    let metaFullPath = this.$route.fullPath;
    let alternateLink = this.$store.state.alternateLink;
    let data = {};
    let city = this.$store.state.cities.find((c) => c.title_eng === this.$store.state.geoData.city);
    city = cityIn(city ? city.title : "Казань");
    if (this.$store.state.vehicles_page && this.$store.state.vehicles_page.page) {
      data = this.$store.state.vehicles_page.page;
    }
    let img = this.getImg(data) || "";
    const title = (data.meta_title || data.title).replace(/{{ city }}/g, `в ${city}`);
    return {
      title,
      script: [
        {
          type: "application/ld+json",
          json: {
            "@context": "https://schema.org",
            "@type": "WebPage",
            name: title,
            description: (data.meta_description || "").replace(/{{ city }}/g, `в ${city}`),
          },
        },
        {
          type: "application/ld+json",
          json: {
            "@context": "http://www.schema.org",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: "1",
                name: "Главная",
                item: api,
              },
              {
                "@type": "ListItem",
                position: "2",
                name: metaName,
                item: api + metaFullPath,
              },
            ],
          },
        },
      ],
      link: [
        { rel: "canonical", href: alternateLink },
        { rel: "alternate", hreflang: "x-default", href: alternateLink },
      ],
      meta: [
        {
          vmid: "keywords",
          name: "keywords",
          content: (data.meta_keywords || "").replace(/{{ city }}/g, `в ${city}`),
        },
        {
          vmid: "description",
          name: "description",
          content: (data.meta_keywords || "").replace(/{{ city }}/g, `в ${city}`),
        },
        {
          vmid: "og:title",
          name: "og:title",
          content: title,
        },
        {
          vmid: "og:image",
          name: "og:image",
          content: img,
        },
        {
          vmid: "og:description",
          name: "og:description",
          content: (data.meta_keywords || "").replace(/{{ city }}/g, `в ${city}`),
        },
      ],
    };
  },
  components: {
    EditorJSComponent,
    InfoCircleIcon,
    BreadcrumbsComponent,
    KinesisContainer,
    KinesisElement,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/global/global.styl"
@import "~@/styles/parts/prg.styl"
@import "~@/styles/layouts/layout.styl"
.services-page {
  .prg__conditions__list {
    margin-bottom 10px
  }

  .vehicle-btn {
    @extend .btn-dark
    flex-direction column
    justify-content center
    align-items center
    height 130px

    .icon {
      width 80px
      height 60px
      margin 0 0 14px

      svg {
        width 100%
        height 100%
      }
    }
  }
}
</style>
